.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 0 20px;
  color: #353535;
  position: fixed;
}
@media (max-width: 1200px) {
  .filter {
    position: relative;
  }
}
.filter .filter__block {
  display: flex;
  align-items: center;
}
.filter .filter__block .block__button {
  padding: 15px;
  border: none;
  box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.4666666667);
}
.filter .filter__block .block__text {
  margin-left: 10px;
  font-weight: 700;
}
.filter .filter__block .active {
  background-color: teal;
}/*# sourceMappingURL=styles.css.map */