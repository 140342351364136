.verbs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 50px;
  color: #353535;
}
.verbs .verbs__ul {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  width: 100%;
  padding: 0;
  list-style: none;
}
.verbs .verbs__ul .ul__header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  font-size: 20px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__header {
    font-size: 13px;
  }
}
.verbs .verbs__ul .ul__li {
  background-color: #fff;
  box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.4666666667);
  margin-bottom: 20px;
}
.verbs .verbs__ul .ul__li .blured {
  padding: 5px 2px;
  cursor: pointer;
  filter: blur(4px);
}
.verbs .verbs__ul .ul__li .blured:active {
  filter: blur(0px);
  color: #353535;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .blured {
    padding: 3px 2px;
  }
  .verbs .verbs__ul .ul__li .blured:hover {
    filter: blur(0px);
    color: #353535;
  }
}
.verbs .verbs__ul .ul__li .li__verb {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 3px;
}
.verbs .verbs__ul .ul__li .li__verb .verb__text {
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-left: 3px;
  height: 100%;
  font-weight: 700;
  justify-content: center;
  font-size: 20px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__verb .verb__text {
    font-size: 13px;
  }
}
.verbs .verbs__ul .ul__li .li__controller {
  display: flex;
  align-items: stretch;
}
.verbs .verbs__ul .ul__li .li__controller .controller__translate {
  margin: 0 auto 0 5px;
  font-size: 20px;
  padding: 5px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__translate {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__translate {
    padding: 3px;
  }
}
.verbs .verbs__ul .ul__li .li__controller .controller__translate :nth-child(1) {
  margin-right: 5px;
}
.verbs .verbs__ul .ul__li .li__controller .controller__translate :nth-child(2) {
  padding: 3 7px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__translate :nth-child(2) {
    padding: 2px;
  }
}
.verbs .verbs__ul .ul__li .li__controller .controller__button {
  background-color: teal;
  color: #fff;
  border: none;
  margin-left: 5px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__button {
    padding: 3px;
  }
}
.verbs .verbs__ul .ul__li .li__controller .controller__button--remove {
  background-color: red;
  padding: 0 6px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__button--remove {
    padding: 0 6px;
  }
}
.verbs .title {
  text-align: center;
  margin-top: 0;
}
.verbs .addingBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  gap: 12px;
}
.verbs .addingBlock .addingBlock__td {
  margin-top: 10px;
}
.verbs .addingBlock .addingBlock__input {
  width: 150px;
  border: none;
  padding: 10px;
  box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.4666666667);
}
.verbs .addingBlock .addingBlock__button {
  width: 100px;
  border: none;
  background-color: teal;
  color: #fff;
  padding: 10px;
  box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.4666666667);
}/*# sourceMappingURL=styles.css.map */