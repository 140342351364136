@import "../../theme";

.game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin: auto;
    font-size: 20px;
    @media (max-width: $mobileMax) {
        font-size: 15px;
    }

    .game__title {
        font-size: 50px;
        margin-bottom: 10vh;
        margin-top: 10vh;
        text-align: center;

        @media (max-width: $mobileMax) {
            flex-direction: column;
            font-size: 35px;
        }
    }

    .form {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
        font-size: 30px;

        @media (max-width: $mobileMax) {
            flex-direction: column;
            font-size: 25px;
        }

        .input {
            border: none;
            border-radius: 6px;
            padding: 5px;
        }

        .ok {
            outline: 4px solid #0f07;
        }

        .mistake {
            outline: 4px solid #f007;
        }

        .button {
            color: $textInComponent;
            outline-color: teal;
            font-weight: 800;
            border: none;
            border-radius: 6px;
            box-shadow: 1px 1px 4px #3337;
            margin: auto;
        }
    }

    .button {
        color: $textInComponent;
        outline-color: teal;
        font-weight: 800;
        padding: 7px 18px;
        border: none;
        border-radius: 6px;
        box-shadow: 1px 1px 4px #3337;
        margin: auto;
    }

    .buttonsBlock {
        display: flex;
        gap: 20px;
        justify-content: space-around;
    }
}