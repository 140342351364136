.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin: auto;
  font-size: 20px;
}
@media (max-width: 767px) {
  .game {
    font-size: 15px;
  }
}
.game .game__title {
  font-size: 50px;
  margin-bottom: 10vh;
  margin-top: 10vh;
  text-align: center;
}
@media (max-width: 767px) {
  .game .game__title {
    flex-direction: column;
    font-size: 35px;
  }
}
.game .form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  font-size: 30px;
}
@media (max-width: 767px) {
  .game .form {
    flex-direction: column;
    font-size: 25px;
  }
}
.game .form .input {
  border: none;
  border-radius: 6px;
  padding: 5px;
}
.game .form .ok {
  outline: 4px solid rgba(0, 255, 0, 0.4666666667);
}
.game .form .mistake {
  outline: 4px solid rgba(255, 0, 0, 0.4666666667);
}
.game .form .button {
  color: #fff;
  outline-color: teal;
  font-weight: 800;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 4px rgba(51, 51, 51, 0.4666666667);
  margin: auto;
}
.game .button {
  color: #fff;
  outline-color: teal;
  font-weight: 800;
  padding: 7px 18px;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 4px rgba(51, 51, 51, 0.4666666667);
  margin: auto;
}
.game .buttonsBlock {
  display: flex;
  gap: 20px;
  justify-content: space-around;
}/*# sourceMappingURL=styles.css.map */