*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
  color: #252525;
}

button {
  cursor: pointer;
}

button:hover {
  filter: brightness(115%);
}

button:active {
  filter: brightness(90%);
}