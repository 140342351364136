.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
  width: 100%;
}
.auth .auth__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 270px;
}
.auth .auth__form .button {
  background-color: teal;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  font-weight: 700;
  font-size: 15px;
}
.auth .auth__form .input {
  border-radius: 6px;
  padding: 12px 20px;
}/*# sourceMappingURL=styles.css.map */