@import "../../../theme";

@mixin buttonComponent {
    background-color: $componentBackgroundColor;
    color: $textInComponent;
    border: none;
}

.blur {
    filter: blur(2px);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 10px 120px;
    min-width: 290px;
    color: $textColor;

    .container__learnedButton {
        @include buttonComponent;
        margin-bottom: 20px;
        padding: 10px;
        font-size: 20px;
        font-weight: 700;
    }

    .addButton {
        @include buttonComponent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        border: 2px solid $textInComponent;
        bottom: 0;
        right: 0;
        margin: 20px;
        padding: 30px;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        font-size: 60px;
    }

    .words {
        display: grid;
        width: 100%;
        grid-template-columns: auto auto 1fr auto auto;
        align-items: center;
        background-color: white;
        box-shadow: 1px 1px 4px #2225;
        border-radius: 5px;
        width: 100%;
        max-width: 700px;
        margin-bottom: 7px;

        .words__word {
            font-size: 24px;
            font-weight: 700;
            margin-left: 5px;

            @media (max-width: $mobileMax) {
                font-size: 15px;
            }
        }

        .words__translate {
            display: block;
            filter: blur(4px);
            padding: 5px 0px 5px 10px;
            margin-right: 3px;
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;

            &:active {
                background-color: #eee;
                filter: blur(0px);
            }

            @media (max-width: $mobileMax) {
                font-size: 14px;

                &:hover {
                    filter: blur(0);
                }
            }
        }

        .words__button {
            @include buttonComponent;
            margin-left: auto;
            width: 100%;
            height: 100%;

            @media (max-width: $mobileMax) {
                font-size: 14px;
            }
        }

        .words__removeButton {
            @include buttonComponent;
            height: 100%;
            background-color: red;
        }
    }

    .addWord {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 90px 40px;
        max-width: 240px;

        .addWord__input {
            border-radius: 6px;
            padding: 0 20px;
            margin-bottom: 9px;
            height: 45px;
            font-size: 15px;
        }

        .addWord__button {
            border-radius: 6px;
            padding: 12px 20px;
            margin-bottom: 9px;
            @include buttonComponent;
            border: 1px solid $backgroundColor
        }
    }
}

.error {
    color: #f90000;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 7px;
}