.search {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}
.search .search__input {
  color: teal;
  outline-color: teal;
  font-weight: 800;
  padding: 7px 12px;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 4px rgba(51, 51, 51, 0.4666666667);
}/*# sourceMappingURL=styles.css.map */