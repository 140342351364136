@import "../../../_theme.scss";

$shadow: 1px 1px 4px #2227;

@mixin text {
    font-size: 20px;

    @media (max-width: $mobileMax) {
        font-size: 13px;
    }
}

.verbs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 20px 50px;
    color: $textColor;

    .verbs__ul {
        display: flex;
        flex-direction: column;
        max-width: 750px;
        width: 100%;
        padding: 0;
        list-style: none;

        .ul__header {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            text-align: center;
            @include text;
        }

        .ul__li {
            background-color: $textInComponent;
            box-shadow: $shadow;
            margin-bottom: 20px;

            .blured {
                padding: 5px 2px;
                cursor: pointer;
                filter: blur(4px);

                &:active {
                    filter: blur(0px);
                    color: $textColor;
                }

                @media (max-width: $mobileMax) {
                    padding: 3px 2px;

                    &:hover {
                        filter: blur(0px);
                        color: $textColor;
                    }
                }
            }

            .li__verb {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                margin-bottom: 3px;

                .verb__text {
                    display: flex;
                    align-items: center;
                    padding-left: 5px;
                    margin-left: 3px;
                    height: 100%;
                    font-weight: 700;
                    justify-content: center;

                    @include text;
                }
            }

            .li__controller {
                display: flex;
                align-items: stretch;

                .controller__translate {
                    margin: 0 auto 0 5px;
                    @include text;
                    padding: 5px;

                    @media (max-width: $mobileMax) {
                        padding: 3px;
                    }

                    :nth-child(1) {
                        margin-right: 5px;
                    }

                    :nth-child(2) {
                        padding: 3 7px;

                        @media (max-width: $mobileMax) {
                            padding: 2px;
                        }
                    }
                }

                .controller__button {
                    background-color: $componentBackgroundColor;
                    color: $textInComponent;
                    border: none;
                    margin-left: 5px;

                    @media (max-width: $mobileMax) {
                        padding: 3px;
                    }
                }

                .controller__button--remove {
                    background-color: red;
                    padding: 0 6px;

                    @media (max-width: $mobileMax) {
                        padding: 0 6px;
                    }
                }
            }
        }
    }

    .title {
        text-align: center;
        margin-top: 0;
    }

    .addingBlock {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        gap: 12px;

        .addingBlock__td {
            margin-top: 10px;
        }

        .addingBlock__input {
            width: 150px;
            border: none;
            padding: 10px;
            box-shadow: $shadow
        }

        .addingBlock__button {
            width: 100px;
            border: none;
            background-color: $componentBackgroundColor;
            color: $textInComponent;
            padding: 10px;
            box-shadow: $shadow
        }
    }
}